<template>
  <div class="bg">
    <div class="pl-3 pt-3 mb-4">
      <b-img width="220" src="./img/logo-preta.png"></b-img>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-sm-12 col-md-4 align-center">
        <div class="d-flex justify-center">
          <img src="/img/check-sucess-outline.svg">
          <h2 class="green bold mt-3 ml-3">Pagamento aprovado</h2>
        </div>

        <img class="green mt-5" src="/img/fatura-paga.svg" />

        <h5 class="sub-title mt-5">Tudo certo com o pagamento! 
          Agora você já pode aproveitar todos os benefícios do seu registro de bicicleta. Você já pode fechar esta janela.</h5>

        <h6 class="sub-title mt-5">Baixe o aplicativo oficial da Bike Registrada para acompanhar seu seguro e registrar sua bike. Disponível para dispositivos ANDROID e IOS</h6>

        <div class="d-flex mt-5 justify-center">
          <img class="pointer" @click.prevent="redirectIos" src="/img/ios.svg">
          <img class="ml-3 pointer" @click.prevent="redirectPlay" src="/img/play.svg">
        </div>

        <div class="footer-payment mt-5">
          <h6>Bike Registrada © 2013 - {{ ano }}. Todos os direitos reservados.</h6>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return { ano: undefined }
  },
  created() {
    this.ano = new Date(new Date()).getFullYear();
  },

  mounted() {
  },

  watch: {

  },

  methods: {
    redirectIos() {
      return window.open('https://apps.apple.com/br/app/bike-registrada/id1661678422', '_blank');
    },
    redirectPlay() {
      return window.open('https://play.google.com/store/apps/details?id=br.com.seguro.bikeregistrada', '_blank');
    },
  }
};

</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url("https://fonts.googleapis.com/css?family=Open%20Sans:wght@100..900&display=swap");

.row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.pointer {
  cursor: pointer;
}

.sub-title {
  color: #6F6C8F;
}

.bold {
  font-family: "Inter";
  font-weight: 700;
}

.green {
  color: #4FA935 !important;
}

.footer-payment {
  padding: 12px;
  left: 0;
  bottom: 0;
  width: 100%;
}

.bg {
  background-image: url("/img/bike-mundo.svg");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 30%;
  background-color: #F9F9F9;
  font-family: Inter !important;
  min-height: 100%;
}

.align-center {
  text-align: center;
}

.justify-center {
  justify-content: center;
}
</style>
